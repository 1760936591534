import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import video_mute from "../../Images/video_mute.png";
import video_recording from "../../Images/video_recording.png";
import mic from "../../Images/mic.gif";
import {
  getPracticeQuestion,
  postPracticeQuestion,
  getPracticeRestoreSession,
  skipPracticeQuestion,
  submitPracticeEterna,
} from "../../redux/actions";
import HeaderComponent from "../../components/Header";
import real_think from "../../Images/real_think.png";
import { toast } from "react-toastify";
import getBlobDuration from "get-blob-duration";
import timer1 from "../../Images/Timer1.png";
import timer2 from "../../Images/Timer2.png";
import timer3 from "../../Images/Timer3.png";
import start from "../../Images/start.png";
import Loader from "react-loader";
import ringtone from "../../Sounds/new-ringtone.mp3";
import { browserName, browserVersion, isMobile } from "react-device-detect";
import { stopwatch } from "durations";
import connection from "../../config/connection";
import FacebookPixel from '../../MetaComponents/Facebookpixel';
import $ from 'jquery';
import 'jquery-confirm/dist/jquery-confirm.min.css';
import 'jquery-confirm/dist/jquery-confirm.min.js';

const watch = stopwatch();

const videoIOSType = "video/mp4";
const videoWebType = "video/webm";
const iosCheck =
  /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
const details = navigator.userAgent;
const regexp = /android|iphone|kindle|ipad/i;
const isMobileDevice =
  regexp.test(details) ||
  (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

class Practice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVideoRecoding: false,
      recording: false,
      recordingAfter: false,
      videos: [],
      data: {},
      nextpage: "",
      recordedVideo: {},
      category: "",
      subcategory: "",
      numberRecordings: "",
      stream: {},
      submitFlag: false,
      completedata: {},
      videoUrl: "",
      duration: "",
      seconds: 4,
      isSubmitted: false,
      loaded: true,
      timer: 0,
      screen_mode: "landscape",
      checkTransition: false,
      videoInformativeModal:false,
      firstTimePop: false,
      hasMicrophone: false,
      hasWebcam: false,
      isProcessing: false,
      videoOptions: {
        video: {
          width: { min: 320, ideal: 1920, max: 1920 },
          height: { min: 180, ideal: 1080, max: 1080 },
          aspectRatio: 1.7777777778,
          facingMode: "user",
          // width: { min: 320, ideal: 580, max: 1920 },
          // height: { min: 240, ideal: 420, max: 1080 },
          // aspectRatio: 1.777777778,
        },
        audio: true,
      },
      videoFacing: "user",
      recordingHours: 0,
      recordingMinutes: 0,
      recordingSeconds: 0,
      recordingMiliSeconds: 0,
      isRetakeSec: false, // used boolean  isRetakeSec to dynamically update its value based on sect 60-120 on video retake
      record_click: "submit",
    };
    this.ManageOrientation = this.ManageOrientation.bind(this);
    this.countRef = React.createRef(null);
    this.myInterval = React.createRef(null);
    this.baseName = '';
    this.basePath =  connection.homePage;

  }

  format(num) {
    return (num + "").length === 1 ? "0" + num : num + "";
  }

  async componentDidMount() {

    const { location } = this.props;
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    if(splitLocation[1]=='dating')
    {
      this.baseName = 'dating';
      this.basePath =  connection.datingHomePage;
    }
    else
    {
      this.baseName = 'home';
      this.basePath =  connection.homePage;
    }



    if (this.props.auth.loginUserToken == "") {
      localStorage.clear();
      window.location.href = this.props.auth.loginUserToken ? this.basePath + `?access_token=${this.props.auth.loginUserToken}` :  this.basePath
      // this.props.history.replace("/");
    }
    this.ManageOrientation();
    //      this.getQuestion();
    this.setState({ loaded: false });
    let url = `practice-question/`;
    this.props.getPracticeRestoreSession(
      this.props.auth.loginUserToken,
      (res) => {
        this.setState({ loaded: true });
        if (res.id && res.id != "") {
          url = `practice-question/?restore_session=${res.id}`;
        }

        this.props.getPracticeQuestion(
          url,
          this.props.auth.loginUserToken,
          (response) => {
            if (response.data && response.data.length > 0) {
              if (response.data[0] && response.data[0].transition == true) {
                if (this.state.checkTransition == false) {
                  this.setState({ firstTimePop: false, checkTransition: false });
                } else {
                  this.setState({ firstTimePop: false });
                }
              }
              this.setState({
                data: response.data[0],
                nextpage: response.current_page,
                completedata: response,
              });
              this.setState({
                recording: false,
                numberRecordings: "",
                isSubmitted: false,
              });
            } else {
              let payload = {
                title: "PRACTICE",
                name: this.props.auth.loginUserInfo.first_name
                  ? this.props.auth.loginUserInfo.first_name
                  : "",
                email: this.props.auth.loginUserInfo.email
                  ? this.props.auth.loginUserInfo.email
                  : "",
                type: "video",
              };
              this.props.submitPracticeEterna(
                payload,
                this.props.auth.loginUserToken,
                (response1) => {
                  if (response1.msg) {
                    this.props.history.push(`/${this.baseName}/practice-interact/`);
                  } else {
                    toast(response1.error, {
                      position: "top-right",
                      autoClose: 3000,
                      type: "error",
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                      progress: undefined,
                    });
                  }
                }
              );
            }
          }
        );
      }
    );

    this.video.src = this.video.srcObject = null;
    this.video.muted = false;
    this.video.volume = 1;
  }

  getQuestion() {
    //    alert('Entered');
    this.setState({
      duration: 0,
      recordingMiliSeconds: 0,
      recordingHours: 0,
      recordingMinutes: 0,
      recordingSeconds: 0,
      numberRecordings: "",
      current_time_recording: "",
      record_click: "nextQuestion",
    });
    this.setState({ loaded: false });
    let url = `practice-question/`;
    this.props.getPracticeRestoreSession(
      this.props.auth.loginUserToken,
      (res) => {
        this.setState({ loaded: true });
        //     alert('Step 1');
        if (res.id && res.id != "") {
          url = `practice-question/?restore_session=${res.id}`;
        }

        //    alert('Step 2');
        this.props.getPracticeQuestion(
          url,
          this.props.auth.loginUserToken,
          (response) => {
            //      alert('Step 3');
            if (response.data && response.data.length > 0) {
              //  alert(response.data[0].transition);
              if (response.data[0] && response.data[0].transition == true) {
                //      alert(response.data[0].transition);
                //     alert('Step 4');
                if (this.state.checkTransition == false) {
                  this.setState({ firstTimePop: false, checkTransition: false });
                } else {
                  this.setState({ firstTimePop: false });
                }
              }
              this.setState({
                data: response.data[0],
                nextpage: response.current_page,
                completedata: response,
              });
              this.setState({
                recording: false,
                numberRecordings: "",
                isSubmitted: false,
              });
            } else {
              let payload = {
                title: "PRACTICE",
                name: this.props.auth.loginUserInfo.first_name
                  ? this.props.auth.loginUserInfo.first_name
                  : "",
                email: this.props.auth.loginUserInfo.email
                  ? this.props.auth.loginUserInfo.email
                  : "",
                type: "video",
              };
              this.props.submitPracticeEterna(
                payload,
                this.props.auth.loginUserToken,
                (response1) => {
                  if (response1.msg) {
                    this.props.history.push(`/${this.baseName}/practice-interact/`);
                  } else {
                    toast(response1.error, {
                      position: "top-right",
                      autoClose: 3000,
                      type: "error",
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                      progress: undefined,
                    });
                  }
                }
              );
            }
          }
        );
      }
    );
  }

  videoOptionsChange = (e) => {
    // alert(e.target.value);
    let videoOptions = {
      video: {
        width: { min: 320, ideal: 1920, max: 1920 },
        height: { min: 180, ideal: 1080, max: 1080 },
        aspectRatio: 1.7777777778,
        facingMode: e.target.value,
        // width: { min: 320, ideal: 580, max: 1920 },
        // height: { min: 240, ideal: 420, max: 1080 },
        // aspectRatio: 1.777777778,
      },
      audio: true,
    };

    this.setState({ videoOptions: videoOptions, videoFacing: e.target.value });
  };

  async getCameraStream() {
    this.setState({ numberRecordings: "" });
    var audio = new Audio(ringtone);
    // Older browsers might not implement mediaDevices at all, so we set an empty object first
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {};
    }
   
    if (
      navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia != undefined
    ) {
      await navigator.mediaDevices
        .getUserMedia(this.state.videoOptions)
        .then((stream) => {
          this.setState({ isVideoRecoding: true });
          //  alert(stream);
          this.stream = stream;
          this.chunks = [];
          this.video.srcObject = stream;
          this.video.muted = true;
          this.video.volume = 0;

          audio.play();
          this.setState({ seconds: 3 });

          this.video.play();

          this.setState({ recording: true });

          this.myInterval = setInterval(() => {
            if (this.state.seconds > 0) {
              audio.play();
              this.setState({ seconds: this.state.seconds - 1 });
            } else if (this.state.seconds <= 0) {
              clearInterval(this.myInterval);
              this.setState({ seconds: this.state.seconds - 1 });
              //  this.setState({ timer: this.state.timer + 1 });
              this.countRef.current = setInterval(() => {
                if (this.state.timer == 120) {
                  this.stopRecording();
                } else {
                  this.setState({ timer: this.state.timer + 1 });
                }
              }, 1000);
              // listen for data from media recorder
              this.setState({ recordingAfter: true });
              this.setState({ isActive: true, isPaused: true });

              if (iosCheck == false) {
                this.mediaRecorder = new MediaRecorder(stream, {
                  mimeType:
                    `${browserName}` == "Safari" || `${browserName}` == "safari"
                      ? videoIOSType
                      : videoWebType,
                });
              } else {
                this.mediaRecorder = new MediaRecorder(stream, {
                  mimeType: videoIOSType,
                });
              }

              this.mediaRecorder.start(10);
              if (watch.isRunning()) {
                watch.stop();
                watch.reset();
              }
              watch.start();

              this.setState({ stream: this.mediaRecorder.stream });
              var interval = 0;
              setInterval(() => {
                // console.log((watch.duration().hours().toString()).split(".")[0]+":"+(watch.duration().minutes().toString()).split(".")[0]+":"+(((watch.duration().seconds()-interval)).toString()).split(".")[0]);
                this.state.recordingMiliSeconds = watch
                  .duration()
                  .millis()
                  .toString()
                  .split(".")[0];
                this.state.recordingHours = watch
                  .duration()
                  .hours()
                  .toString()
                  .split(".")[0];
                let new_time = "";
                let plus_point = 0;
                if (this.state.record_click == "retaik" || "nextQuestion") {
                  let nnn = watch.duration().seconds();

                  if (nnn > 58 && nnn < 118) {
                    this.setState({ isRetakeSec: true }); //code: to update interval between 60secto 120 sec  used boolean isRetakeSec
                  } else {
                    this.setState({ isRetakeSec: false });
                  }
                  if (nnn < 60) {
                    if (interval > 0) {
                      plus_point = interval > 60 ? interval : 60;
                    }

                    new_time =
                      watch.duration().seconds() -
                      interval +
                      parseInt(plus_point);
                  } else {
                    new_time = watch.duration().seconds() - interval;
                  }
                }
                else {
                  new_time = watch.duration().seconds() - interval;
                }
                // alert(new_time,"<- new_time")
                // alert( ( new_time ).toString().split(".")[0],"<-  ( new_time ).toString().split")
                if (new_time.toString().split(".")[0] == 0) {
                  this.state.recordingMinutes = watch
                    .duration()
                    .minutes()
                    .toString()
                    .split(".")[0];
                }
                this.state.recordingSeconds = new_time.toString().split(".")[0];
                if (this.state.isRetakeSec) {
                  //code: to update interval between 60svec to 120 sec  based on  boolean isRetakeSec
                  interval = 60;
                } else if (
                  !this.state.isRetakeSec &&
                  watch.duration().seconds().toString().split(".")[0] -
                    interval ==
                    59
                ) {
                  interval = interval + 60;
                }
                // console.log(interval);

                // console.log(interval);
              }, 1000);

              this.mediaRecorder.ondataavailable = (e) => {
                if (e.data && e.data.size > 0) {
                  this.chunks.push(e.data);
                }
              };
              return stream;
            }
          }, 1000);
          // return ()=> {
          //     clearInterval(myInterval);
          // };
        })
        .catch((err) => {
          console.error("camera enable error")
          console.error(err); 
          
          $.alert({
            title: 'Alert!',
            content: 'Please enable your camera and/or microphone in order to create your Eternaview',
          });
          this.setState({ hasWebcam: false });
          this.setState({ hasMicrophone: false });
          return false;
          //throw new Error("Unable to fetch stream " + err);
        });
      //  return localStream;
    } else {
      $.alert({
        title: 'Alert!',
        content: 'getUserMedia not supported on your browser!',
      });
    }
  }

  startRecording(e) {
    e.preventDefault();

    this.handleReset();
    if (watch.isRunning()) {
      watch.stop();
      watch.reset();
    }

    this.getCameraStream();
  }

  formatTime = (timer) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours}:${getMinutes}:${getSeconds}`;
  };

  handlePause = () => {
    clearInterval(this.countRef.current);
    this.setState({ isPaused: false });
  };

  handleReset = () => {
    clearInterval(this.countRef.current);
    this.setState({ isActive: false, isPaused: false });
    this.setState({ timer: 0 });
  };

  getTimeDuration = () => {
    let totalSeconds;
    if (
      this.state.recordingHours === 0 &&
      this.state.recordingMinutes === 0 &&
      this.state.recordingSeconds === 0
    ) {
      totalSeconds = new Date(this.state.duration * 1000)
        .toISOString()
        .substr(11, 8);
    } else {
      const hours = this.format(this.state.recordingHours) * 3600;
      const minutes = this.format(this.state.recordingMinutes) * 60;
      totalSeconds =
        parseInt(this.format(this.state.recordingSeconds)) +
        parseInt(hours) +
        parseInt(minutes);
    }
    return totalSeconds;
  };

  stopRecording(e) {
    if (e) e.preventDefault();
    if (watch.isRunning()) {
      watch.stop();
      watch.reset();
    }
    this.performStopRecording();
  }

  performStopRecording() {
    this.video.srcObject = this.video.src = null;
    this.video.muted = false;
    this.video.volume = 1;
    clearInterval(this.countRef.current);

    this.setState({ isPaused: false });
    this.setState({
      recording: false,
      numberRecordings: "1",
      recordingAfter: false,
      isProcessing: true,
      isVideoRecoding: false,
    });

    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
    }

    if (iosCheck == false) {
      const blob = new Blob(this.chunks, {
        type:
          `${browserName}` == "Safari" || `${browserName}` == "safari"
            ? videoIOSType
            : videoWebType,
      });

      this.setState({ recordedVideo: blob });
      //  const videoURL = window.URL.createObjectURL(blob);
      const videoURL = window.webkitURL.createObjectURL(blob);

      this.setState({ videoUrl: videoURL });

      let that = this;
      const duration = this.getTimeDuration();
      that.setState({ duration: duration, isProcessing: false });
      // getBlobDuration(blob)
      //   .then(function (duration) {
      //     that.setState({ duration: duration, isProcessing: false });
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
    } else {
      const blob = new Blob(this.chunks, { type: videoIOSType });

      this.setState({ recordedVideo: blob });
      //  const videoURL = window.URL.createObjectURL(blob);
      const videoURL = window.webkitURL.createObjectURL(blob);

      this.setState({ videoUrl: videoURL });

      let that = this;
      const duration = this.getTimeDuration();
      that.setState({ duration: duration, isProcessing: false });
      // getBlobDuration(blob)
      //   .then(function (duration) {
      //     that.setState({ duration: duration, isProcessing: false });
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
    }

    if (iosCheck == false) {
      this.state.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }

  skipQuestion = (e) => {
    e.preventDefault();
    if (this.state.isVideoRecoding) {
      this.stopRecording(e);
    }

    this.setState({ loaded: false });
    this.props.skipPracticeQuestion(
      { question_id: this.state.data.id },
      this.props.auth.loginUserToken,
      (res) => {
        this.setState({ loaded: true });
        //  if (this.state.completedata.next != null) {
        this.setState({ seconds: 4 });
        this.setState({ timer: 0 });
        this.getQuestion();
      }
    );
  };

  ManageOrientation = () => {
    var mql = window.matchMedia("(orientation: portrait)");
    if (mql.matches) {
      this.setState({ screen_mode: "portrait" });
    } else {
      this.setState({ screen_mode: "landscape" });
    }

    const callBack = (m) => {
      if (m.matches) {
        this.setState({ screen_mode: "portrait" });
      } else {
        this.setState({ screen_mode: "landscape" });
      }
    };
    mql.addListener(callBack);
  };

  saveToServer = (e) => {
    e.preventDefault();
    if (watch.isRunning()) {
      watch.stop();
      watch.reset();
    }
    this.state.recordingMiliSeconds = 0;
    this.state.recordingHours = 0;
    this.state.recordingMinutes = 0;
    this.state.recordingSeconds = 0;
    this.setState({
      showRecording: true,
      duration: "",
      recordingMiliSeconds: 0,
      recordingHours: 0,
      recordingMinutes: 0,
      recordingSeconds: 0,
    });

    if (this.state.numberRecordings == "") {
      toast("Please record your answer to submit successfully.", {
        position: "top-right",
        autoClose: 3000,
        type: "warning",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });

      return;
    }

    if (this.state.duration != "") {
      this.setState({ loaded: false });
      this.setState({ submitFlag: true });
      let formData = new FormData();
      formData.append("video", this.state.recordedVideo);
      formData.append("question_id", this.state.data.id);
      formData.append("duration", this.state.duration);
      formData.append("size", this.state.recordedVideo.size);
      formData.append("screen_mode", this.state.screen_mode);
      this.props.postPracticeQuestion(
        formData,
        this.props.auth.loginUserToken,
        (res) => {
          this.setState({ submitFlag: false, isSubmitted: true });
          this.setState({ loaded: true });
          this.setState({ timer: 0, seconds: 4 });
          if (res.status == 200) {
            this.state.recordingMiliSeconds = 0;
            this.state.recordingHours = 0;
            this.state.recordingMinutes = 0;
            this.state.recordingSeconds = 0;
            toast("Answer saved.", {
              position: "top-right",
              autoClose: 3000,
              type: "success",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            });

            this.getQuestion();
          }
        }
      );
    } else {
      toast(
        "Please wait until processing is complete before trying to submit.",
        {
          position: "top-right",
          autoClose: 3000,
          type: "error",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        }
      );
    }
  };

  takeBreak = (e) => {
    e.preventDefault();

    this.props.history.replace(`/${this.baseName}/create/`);
  };

  retakeVideo(e) {
    e.preventDefault();

    this.setState({
      showRecording: true,
      duration: "",
      recordingMiliSeconds: 0,
      recordingHours: 0,
      recordingMinutes: 0,
      recordingSeconds: 0,
      numberRecordings: "",
      current_time_recording: "",
      newRecording: false,
      isProcessing: false,
      record_click: "retaik",
    });
    this.setState({ seconds: 4 });
    this.setState({ timer: 0 });
    if (watch.isRunning()) {
      watch.stop();
      watch.reset();
    }
    // this.getCameraStream();
  }

  closePopUp = (e) => {
    e.preventDefault();

    this.setState({ firstTimePop: false });
  };
  // closeInfoPopUp = (e) => {
  //   e.preventDefault();

  //   this.setState({ videoInformativeModal: false });
  // };

  componentWillUnmount() {
    clearInterval(this.countRef.current);
    clearInterval(this.myInterval);
    // if(this.mediaRecorder) {
    //     this.mediaRecorder.stop();
    // }
  }

  render() {
    const {
      recording,
      data,
      completedata,
      numberRecordings,
      videoUrl,
      seconds,
      loaded,
      recordingAfter,
    } = this.state;
    return (
      <div>

           <FacebookPixel trackName="Start trial" obj={{value: 60, currency: "USD"}}/>

        <HeaderComponent
          value={this.state.numberRecordings}
          saveToServer={this.saveToServer}
          skipQuestion={this.skipQuestion}
          props={this.props}
        />
        <section className="practing_outer record2 recored3 partices1 custom_partices4 practice_page">
          <Loader loaded={loaded}></Loader>
          <div className="container">
            <div className="row">
              <div className="col-ld-10 col-md-10 offset-lg-1 offset-md-1">
                <div className="middle_outer56 cust67">
                  <div className="tex_fulll2">
                    <h4 className="colre4">Practice </h4>

                    <div
                      id="myModal201"
                      className={
                        this.state.firstTimePop == false
                          ? "modal fade show show_custom_modal"
                          : "modal fade show custom_modal"
                      }
                      role="dialog"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            {/* <button type="button" className="close custom_close3" data-dismiss="modal">&times;</button> */}
                          </div>
                          <div className="modal-body">
                            <p>
                              {/*  The first couple questions, which you are required
                              to record, are intended to enhance the experience
                              of interacting with your practice Eternaview. */}
                              Although we are building the web app to work
                              across all major devices and browsers, design and
                              testing is still in progress. Currently, we
                              recommend that the recording of any Practice
                              Eternaview is done from a Windows PC or Mac,
                              desktop or laptop, using the Google Chrome
                              browser.
                            </p>
                          </div>
                          <div className="modal-footer">
                            <button type="button" onClick={this.closePopUp}>
                              Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div
                      id="myModal201"
                      className={       this.state.videoInformativeModal == false
                        ? "modal fade show show_custom_modal"
                        : "modal fade show custom_modal"}
                      role="dialog"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-body">
                            <p>
                            Answers must be at least 4 seconds. Please do a “Retake”
                            </p>
                          </div>
                          <div className="modal-footer">
                            <button type="button" onClick={this.closeInfoPopUp}>
                              Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="modal" id="myModal">
                      <div className="modal-dialog custom_dioalag">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              Before skipping ahead, do you wish to submit the
                              recording you just made?
                            </h4>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                            >
                              &times;
                            </button>
                          </div>

                          <div className="modal-body">
                            <div className="btn_inner">
                              <ul>
                                <li>
                                  <button
                                    className="btn custom_yes"
                                    onClick={(e) => this.saveToServer(e)}
                                    data-dismiss="modal"
                                  >
                                    Yes, submit!
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="btn custom_no"
                                    onClick={(e) => this.skipQuestion(e)}
                                    data-dismiss="modal"
                                  >
                                    No, just move ahead
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="tex_fulll2 row">
                      <div className="col-md-3">
                        <p className="question_name">
                          Question {completedata.current_page} of{" "}
                          {completedata.total_question_count}
                        </p>
                      </div>
                      <div className="col-md-6">
                      {" "}
                        <h4 className="question_h4">{data?.question}</h4>
                      </div>
                      <div className="col-md-3">
                      <div className="div-skip-question">
                        {numberRecordings == "" && data.transition == false && (
                          <h5 className="skip_ques_h5">
                            <a href="" className={isMobile&&this.state.screen_mode == "portrait" ?"practice_portait_mode":""} onClick={(e) => this.skipQuestion(e)}>
                              Skip Question
                            </a>
                          </h5>
                        )}
                        {numberRecordings != "" && data.transition == false && (
                          <h5 className="skip_ques_h5">
                            <a href="" className={isMobile&&this.state.screen_mode == "portrait" ?"practice_portait_mode":""} data-toggle="modal" data-target="#myModal">
                              Skip Question
                            </a>
                          </h5>
                        )}
                      </div>
                      </div>
                    </div>
                  </div>

                  {numberRecordings == "" && recordingAfter == false && (
                    <div className="same_height">
                      {!recording && numberRecordings == "" ? (
                        <div className="btn7 custom_t6">
                          <button
                            className="btn"
                            onClick={(e) => this.startRecording(e)}
                          >
                            START Recording
                          </button>
                        </div>
                      ) : (
                        <div className="btn7 custom_t6">
                          <button type="button" className="btn" disabled={true}>
                            START Recording
                          </button>
                        </div>
                      )}
                    </div>
                  )}

                  {!recording && numberRecordings != "" && (
                    <div className="btn7 custom_t6">
                      <button className="btn" disabled={numberRecordings != ""}>
                        START Recording
                      </button>
                    </div>
                  )}

                  {recording == true && recordingAfter == true && (
                    <div className="btn7 custom_t6 red_coloe5">
                      <button
                        className="btn"
                        onClick={(e) => this.stopRecording(e)}
                      >
                        STOP Recording
                      </button>
                    </div>
                  )}

                  <div className="img6 practice">
                    {recordingAfter && (
                      <div className="recording_gif">
                        <img src={mic} />
                      </div>
                    )}

                    <div className="timer_outer practice-timer">
                      {seconds == 1 && <img src={timer1} />}
                      {seconds == 2 && <img src={timer2} />}
                      {seconds == 3 && <img src={timer3} />}
                      {seconds == 0 && <img src={start} />}
                    </div>
                    {/* {numberRecordings == "" && recording == false && (
                      <div className="videoCheck practice">
                        {isMobileDevice && (
                          <select
                            className="changeCamera_select"
                            value={this.state.videoFacing}
                            onChange={(e) => this.videoOptionsChange(e)}
                          >
                            <option value="user">Front Camera</option>
                            <option value="environment">Rear Camera</option>
                          </select>
                        )}
                      </div>
                    )} */}

                    <div className="contain-recording-player">
                      <div className="col-md-6 offset-md-3 col-sm-12 submit_retake_col">
                        <div className="btn_outer99 recor67 new_css">
                          <ul className="practice_page_button practice_ul">
                            {numberRecordings == "" && recording == false && (
                              <div className="videoCheck practice">
                                {isMobileDevice && (
                                  <select
                                    className="changeCamera_select"
                                    value={this.state.videoFacing}
                                    onChange={(e) => this.videoOptionsChange(e)}
                                  >
                                    <option value="user">Front Camera</option>
                                    <option value="environment">
                                      Rear Camera
                                    </option>
                                  </select>
                                )}
                              </div>
                            )}
                            {this.state.isProcessing == true && (
                              <li className="Save_outer4">
                                <button className="btn-secondary">
                                  Processing
                                  <i class="fas fa-spinner fa-spin"></i>
                                </button>
                              </li>
                            )}
                            {numberRecordings != "" &&
                              this.state.isProcessing == false &&
                              this.state.submitFlag == false && (
                                <li className="Save_outer4">
                                  <button
                                    // disabled={this.state.duration < 4}
                                    className="btn"
                                    onClick={(e) => this.saveToServer(e)}
                                  >
                                    Submit
                                  </button>
                                </li>
                              )}
                            {this.state.submitFlag == true && (
                              <li className="Save_outer4">
                                <button className="btn">
                                  Submitting...
                                  <i class="fas fa-spinner fa-spin"></i>
                                </button>
                              </li>
                            )}
                            {this.state.isSubmitted == false &&
                              numberRecordings != "" && (
                                <li className="cancel">
                                  <button
                                    className="btn"
                                    onClick={(e) => this.retakeVideo(e)}
                                  >
                                    Retake
                                  </button>
                                </li>
                              )}
                          </ul>
                        </div>
                      </div>
                      {numberRecordings == "" && (
                        <video
                          style={{
                            objectFit: "fill !important",
                            width: 500,
                            height: 280,
                          }}
                          ref={(v) => {
                            this.video = v;
                          }}
                          playsInline
                        >
                          Video stream not available.
                        </video>
                      )}

                      {numberRecordings != "" && (
                        <video
                          id="video_player"
                          style={{
                            objectFit: "fill !important",
                            width: 500,
                            height: 280,
                          }}
                          ref={(v) => {
                            this.videocreated = v;
                          }}
                          src={videoUrl}
                          controls
                          playsInline
                        ></video>
                      )}
                    </div>

                    <div className="partices">
                      <p>
                        Recording time is limited to 2 minutes per question.
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="text_nam546">
                          <p>
                            Recording Time of This Question:
                            {/* {(new Date(this.state.duration * 1000).toISOString()).substr(11, 8)} */}
                            {this.state.recordingHours == 0 &&
                            this.state.recordingMinutes == 0 &&
                            this.state.recordingSeconds == 0
                              ? new Date(this.state.duration * 1000)
                                  .toISOString()
                                  .substr(11, 8)
                              : this.format(this.state.recordingHours) +
                                ":" +
                                this.format(this.state.recordingMinutes) +
                                ":" +
                                this.format(this.state.recordingSeconds)}
                            {/* {console.log(
                              this.state.duration,
                              "<- this.state.duration ",
                              "this.state.recordingSeconds ->",
                              this.state.recordingSeconds
                            )} */}
                          </p>
                        </div>
                      </div>
                      {/* Pickup */}

                      {/* <div className="contain-recording-player">
                      <div className="col-lg-6 col-md-12">
                        <div className="btn_outer99 recor67">
                          <ul className="practice_page_button">
                            {this.state.isProcessing == true && (
                              <li className="Save_outer4">
                                <button className="btn-secondary">
                                  Processing
                                  <i class="fas fa-spinner fa-spin"></i>
                                </button>
                              </li>
                            )}
                            {numberRecordings != "" &&
                              this.state.isProcessing == false &&
                              this.state.submitFlag == false && (
                                <li className="Save_outer4">
                                  <button
                                    className="btn"
                                    onClick={(e) => this.saveToServer(e)}
                                  >
                                    Submit
                                  </button>
                                </li>
                              )}
                            {this.state.submitFlag == true && (
                              <li className="Save_outer4">
                                <button className="btn">
                                  Submitting...
                                  <i class="fas fa-spinner fa-spin"></i>
                                </button>
                              </li>
                            )}
                            {this.state.isSubmitted == false &&
                              numberRecordings != "" && (
                                <li className="cancel">
                                  <button
                                    className="btn"
                                    onClick={(e) => this.retakeVideo(e)}
                                  >
                                    Retake
                                  </button>
                                </li>
                              )}
                          </ul>
                        </div>
                      </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="break_outer45">
                  <img src={real_think} onClick={(e) => this.takeBreak(e)} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = (dispatch) => ({
  getPracticeQuestion: bindActionCreators(getPracticeQuestion, dispatch),
  postPracticeQuestion: bindActionCreators(postPracticeQuestion, dispatch),
  getPracticeRestoreSession: bindActionCreators(
    getPracticeRestoreSession,
    dispatch
  ),
  skipPracticeQuestion: bindActionCreators(skipPracticeQuestion, dispatch),
  submitPracticeEterna: bindActionCreators(submitPracticeEterna, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Practice);
