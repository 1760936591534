import async from "../components/Async";
// Landing
import Landing from "../pages/landing/Landing";

// Auth
import SignIn from "../pages/auth/SignIn";
import SignInNew from "../pages/auth/SignInNew";
import AuthCallbackFb from "../pages/auth/AuthCallbackFb";
import Page404 from "../pages/auth/Page404";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import VerifyEmail from "../pages/auth/VerifyEmail";
import NewPlansPage from "../pages/dashboard/newPlanPage";
import Profile from "../pages/dashboard/Profile";
import CreateIntro from "../pages/dashboard/Create-Intro";
import CreateIntroDating from "../pages/dashboard/Create-Intro-Dating";
import SystemTest from "../pages/dashboard/SystemTest";
import Practice from "../pages/dashboard/Practice";
import VideoRecording from "../pages/recording/VideoRecording";
import RecordingAudio from "../pages/recording/RecordingAudio";
import LiveStreaming from "../pages/recording/LiveStreaming";
import Interact from "../pages/dashboard/Interact";
import InteractNew from "../pages/dashboard/InteractNew";
import InteractDatingNew from "../pages/dashboard/InteractDatingNew";


import BlogPosts from "../pages/dashboard/BlogPosts";
import BlogDetail from "../pages/dashboard/BlogDetail";
import InteractOne from "../pages/dashboard/InteractOne";
import PracticeInteract from  "../pages/dashboard/PracticeInteract";
import MyEternaviews from "../pages/dashboard/MyEternaviews";
import CreateEternaview from "../pages/dashboard/CreateEternaview";
import Faq from "../pages/dashboard/Faq";
import Plans from "../pages/dashboard/Plans";
import pricingFaq from "../pages/dashboard/pricingFaq";
import ForgotPassword from "../pages/auth/forgotPassword";
import CongratulationForm from "../pages/dashboard/CongratulationForm";
import Congratulations from "../pages/dashboard/Congratulations";
import EditEternaview from "../pages/dashboard/EditEternaview";
import InteractFind from "../pages/dashboard/InteractFind";
import Subscribe from "../pages/dashboard/Subscribe";
import SubscribeDone from "../pages/dashboard/SubscribeDone";
import PrebuiltCheckoutStripe from "../pages/dashboard/PrebuiltCheckoutStripe";
import Account from "../pages/dashboard/Account";
import Cancel from "../pages/dashboard/Cancel";
import ContactUs from "../pages/dashboard/ContactUs";
import GeneralPlans from "../pages/dashboard/GeneralPlans";
import GeneralDatingPlans from "../pages/dashboard/GeneralDatingPlans";
import GeneralSquirrelPlans from "../pages/dashboard/GeneralSquirrelPlans";

import Thankyou from "../pages/dashboard/Thankyou";
import Terms from "../pages/dashboard/Terms";
import AboutUs from "../pages/dashboard/AboutUs";
import PrivacyPolicy from "../pages/dashboard/PrivacyPolicy";
import DatingLanding from "../pages/dashboard/DatingLanding";
import SecureLogin from "../pages/dashboard/SecureLogin";
import InteractOneTestscreenLive from "../pages/dashboard/InteractOneTestscreenLive";
import DemoInteractSingle from "../pages/dashboard/DemoInteractSingle";
import DemoInteractGrandpaSingle from "../pages/dashboard/DemoInteractGrandpaSingle";
import PracticeInteractF from "../pages/dashboard/practice-interact";
import NewDefault from "../pages/dashboard/newDefault";
import NewplanScreen from "../pages/dashboard/NewPlanScreen";
import GiftThankyou from "../pages/dashboard/ThankyouGift";
import RedeemGifts from "../components/Gifts/reedem_gift";
import DefaultToWP from "../pages/dashboard/newDefaultWP";
import GeneralPlansTest from "../pages/dashboard/GenralPlansForTest";
import newCreateIntro from "../pages/dashboard/new-create-intro";
import moveToCreatePage from "../pages/dashboard/moveToCreatePage";
import MoveTOPracticePage from "../pages/dashboard/movetoPracticeWP";
import Logout from "../components/Logout";
const Default = async(() => import("../pages/dashboard/Default"));

// Routes
const landingRoutes = {
  path: "/",
  name: "Dashboard",
  // component: Default,
  component: DefaultToWP,
  // component: NewDefault,
  children: null
};




const dashboardRoutes = {
  path: "/home",
  name: "Dashboard",
  children: [
    {
      path: "/oauth/sign-in/",
      name: "Sign In",
      component: SignInNew
    },
    {
      path: "/dating/sign-in/",
      name: "Sign In",
      component: SignInNew
    },
    {
      path: "/oauth/auth-callbackc-fb/",
      name: "Sign In",
      component: AuthCallbackFb
    },
    {
      path: "/dating/auth-callbackc-fb/",
      name: "Sign In",
      component: AuthCallbackFb
    },
    {
      path: "/oauth/sign-up/",
      name: "Sign Up",
      component: SignUp
    },
    {
      path: "/dating/sign-up/",
      name: "Sign Up",
      component: SignUp
    },
    {
      path: "/oauth/forgot-password/",
      name: "Forgot Password",
      component: ForgotPassword
    },
    {
      path: "/dating/forgot-password/",
      name: "Forgot Password",
      component: ForgotPassword
    },
    {
      path: "/oauth/reset-password/:id",
      name: "Reset Password",
      component: ResetPassword
    },
    {
      path: "/dating/reset-password/:id",
      name: "Reset Password",
      component: ResetPassword
    },
    {
      path: "/oauth/verify-email/",
      name: "Verify Email",
      component: VerifyEmail,
    },
    {
      path: "/home/index/:id",
      name: "Home",
      // component: Default,
      component: NewDefault,
    },
    {
      path: "/home/newDefault",
      name: "Home",
      component: NewDefault,
    },
    {
      path: "/home/profile/",
      name: "Home",
      component: Profile,
    },
    {
      path: "/dating/profile/",
      name: "Home",
      component: Profile,
    },
    {
      path: "/home/securelogin/",
      name: "Login",
      component: SecureLogin,
    },
    {
      path: "/home/securelogin/:id/",
      name: "Login",
      component: SecureLogin,
    },
    {
      path: "/home/Legacy+Dating/",
      name: "Create",
      component: DatingLanding,
    },  
    {
      path: "/home/create/",
      name: "Create",
      component:moveToCreatePage
    },
    {
      path: "/dating/create/",
      name: "Create",
      component:moveToCreatePage
    },
    // component: CreateIntro,
    {
      path: "/home/practice/",
      name: "Create",
      component:MoveTOPracticePage
    },
    {
      path: "/dating/practice/",
      name: "Create",
      component:MoveTOPracticePage
    },
    {
      path: "/home/create/Legacy+Dating",
      name: "Create",
      component: CreateIntroDating,
    },
    
    {
      path: "/home/interact/",
      name: "Interact",
      component: InteractNew,
     // component: Interact,
    },
    {
      path: "/dating/interact/",
      name: "Interact",
      component: InteractDatingNew,
     // component: Interact,
    },
    {
      path: "/home/interact-new/",
      name: "Interact",
      component: InteractNew,
    },
    {
      path: "/home/legacy-dating/",
      name: "Interact",
      component: Interact,
    },
    {
      path: "/home/interact-one/:id/:author/:permission?",
      name: "InteractOne",
      component: InteractOneTestscreenLive,
      //  component: InteractOne,
    },
    {
      path: "/dating/interact-one/:id/:author/:permission?",
      name: "InteractOne",
      component: InteractOneTestscreenLive,
      //  component: InteractOne,
    },
    {
      path: "/home/demo-interact/:id/:author/:permission?",
      name: "demo-interact",
      component: DemoInteractSingle,
    
    },
    {
      path: "/home/demo-interact-grandpa/:id/:author/:permission?",
      name: "demo-interact-grandpa",
      component: DemoInteractGrandpaSingle,
    },
    {
      path: "/home/practice-interact/",
      name: "InteractOne",
      component: PracticeInteractF,
      // component: PracticeInteract,
    },
    {
      path: "/dating/practice-interact/",
      name: "InteractOne",
      component: PracticeInteractF,
      // component: PracticeInteract,
    },
    {
      path: "/home/GiftThankyou/",
      name: "GiftThankyou",
      component: GiftThankyou,
      
    },
    {
      path: "/dating/GiftThankyou/",
      name: "GiftThankyou",
      component: GiftThankyou,
      
    },
    {
      path: "/wallet_redeem/:token",
      name: "GiftThankyou",
      component: RedeemGifts,
    },
    {
      path: "/home/wallet_redeem/:token",
      name: "GiftThankyou",
      component: RedeemGifts,
    },
    {
      path: "/dating/wallet_redeem/:token",
      name: "GiftThankyou",
      component: RedeemGifts,
    },
    {
      path: "/home/interact-find/",
      name: "InteractFind",
      component: InteractFind,
    },
    {
      path: "/home/blogs/",
      name: "Blogs",
      component: BlogPosts,
    },
    {
      path: "/home/blogs/:id",
      name: "Blog Detail",
      component: BlogDetail,
    },
    {
      path: "/home/myeternaviews/",
      name: "MyEternaviews",
      component: MyEternaviews,
    },
    {
      path: "/dating/myeternaviews/",
      name: "MyEternaviews",
      component: MyEternaviews,
    },
    {
      path: "/home/faq/",
      name: "FQA",
      component: Faq,
    },
    {
      path: "/home/pricingFaq/",
      name: "FQA",
      component: pricingFaq,
    },
    {
      path: "/home/about-us/",
      name: "AboutUs",
      component: AboutUs,
    },
    {
      path: "/home/interact-one-test-screen-live/:id/:author/:permission?",
      name: "InteractOneTest",
      component: InteractOneTestscreenLive,
    },
    {
      path: "/home/practice-interect-test-screen",
      name: "CreateTestScreen",
      component: PracticeInteractF,
    },
    {
      path: "/home/terms-of-use/",
      name: "Terms",
      component: Terms,
    },
    {
      path: "/dating/terms-of-use/",
      name: "Terms",
      component: Terms,
    },
    {
      path: "/home/privacy-policy/",
      name: "PrivacyPolicy",
      component: PrivacyPolicy,
    },
    {
      path: "/dating/privacy-policy/",
      name: "PrivacyPolicy",
      component: PrivacyPolicy,
    },
    {
      path: "/home/contact-us/",
      name: "ContactUs",
      component: ContactUs,
    },
    {
      path: "/dating/contact-us/",
      name: "ContactUs",
      component: ContactUs,
    },
    {
      path: "/home/plans/:id",
      name: "Plans",
      // component: GeneralPlans,
      component: NewPlansPage,
      // component: Plans,
    },
    {
      path: "/dating/plans/:id",
      name: "Plans",
      // component: GeneralPlans,
      component: NewPlansPage,
      // component: Plans,
    },
    {
      path: "/squirrel/plans/:id",
      name: "Plans",
      // component: GeneralPlans,
      component: NewPlansPage,
      // component: Plans,
    },    
    {
      path: "/home/thank-you-plan/:id",
      name: "Thankyou",
      component: Thankyou,
    },
    {
      path: "/dating/thank-you-plan/:id",
      name: "Thankyou",
      component: Thankyou,
    },
    {
      path: "/squirrel/thank-you-plan/:id",
      name: "Thankyou",
      component: Thankyou,
    },
    {
      path: "/home/pricing-plans",
      name: "General Plans",
      component: GeneralPlans,
    },
    {
      path: "/dating/pricing-plans",
      name: "General Plans",
      component: GeneralDatingPlans,
    },
    {
      path: "/squirrel/pricing-plans",
      name: "General Plans",
      component: GeneralSquirrelPlans,
    },
    {
      path: "/home/NewplanScreen",
      name: "General Plans",
      component: NewplanScreen,
    },

    
	  // {
    //   path: "/home/subscribe/:id",
    //   name: "Subscribe",
    //   component: Subscribe,
    // },
    // {
    //   path: "/home/subscribe-done/:id",
    //   name: "SubscribeDone",
    //   component: SubscribeDone,
    // },
    {
      path: "/home/make-payment",
      name: "PrebuiltCheckoutStripe",
      component: PrebuiltCheckoutStripe,
    },
	 {
      path: "/home/account/",
      name: "Account",
      component: Account,
    },
	 {
      path: "/home/cancel/",
      name: "Cancel",
      component: Cancel,
    }

  ]
};


const authRoutes = {
  path: "/oauth",
  name: "Auth",
  children: [
    {
      path: "/oauth/404/",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/oauth/logmeOut/",
      name: "Log out",
      component: Logout
    },
    {
      path: "/dating/logmeOut/",
      name: "Log out",
      component: Logout
    }
    
  ]
};

const LayoutRoutes = {
  path: "/create",
  name: "Create",
  children: [
    {
      path: "/create/system-test/",
      name: "System Test",
      component: SystemTest,
    },
    {
      path: "/create/practice-test/",
      name: "Practice Test",
      component: Practice,
    },
    {
      path: "/home/create/practice-test/",
      name: "Practice Test",
      component: Practice,
    },
    {
      path: "/dating/create/practice-test/",
      name: "Practice Test",
      component: Practice,
    },
    {
      path: "/create/eternaview/:id",
      name: "CreateEternaview",
      component: CreateEternaview,
    },
    {
      path: "/home/create/eternaview/:id",
      name: "CreateEternaview",
      component: CreateEternaview,
    },
    {
      path: "/dating/create/eternaview/:id",
      name: "CreateEternaview",
      component: CreateEternaview,
    },
    {
      path: "/edit/eternaview/:id",
      name: "EditEternaview",
      component: EditEternaview,
    },
    {
      path: "/home/edit/eternaview/:id",
      name: "EditEternaview",
      component: EditEternaview,
    },
    {
      path: "/dating/edit/eternaview/:id",
      name: "EditEternaview",
      component: EditEternaview,
    },
    {
      path: "/create/congratulations/:id",
      name: "Congratulations",
      component: Congratulations,
    },
    {
      path: "/home/create/congratulations/:id",
      name: "Congratulations",
      component: Congratulations,
    },
    {
      path: "/dating/create/congratulations/:id",
      name: "Congratulations",
      component: Congratulations,
    },
    {
      path: "/create/congratulation/form/:id",
      name: "CongratulationForm",
      component: CongratulationForm,
    },
    {
      path: "/home/create/congratulation/form/:id",
      name: "CongratulationForm",
      component: CongratulationForm,
    },
    {
      path: "/dating/create/congratulation/form/:id",
      name: "CongratulationForm",
      component: CongratulationForm,
    },
    {
      path: "/video-record",
      name: "video-record",
      component: VideoRecording
    },
    {
      path: "/audio-record",
      name: "audio-record",
      component: RecordingAudio
    },
    {
      path: "/live-streaming",
      name: "live-streaming",
      component: LiveStreaming
    },
  ]
};


// Dashboard specific routes
export const dashboard = [
  dashboardRoutes
];

// Landing specific routes
export const landing = [landingRoutes];

// Auth specific routes
export const page = [authRoutes];

export const layout = [LayoutRoutes];

// All routes
export default [
  dashboardRoutes,
  landingRoutes
];
