import React, { useEffect, useState } from "react";
import fb from "../Images/fb.png";
import yt from "../Images/video.png"
import tw from "../Images/tw.png";
import linkdin from "../Images/linkdin.png";
import footer_logo from "../Images/footer_logo.png";
import { Link, useLocation } from "react-router-dom";
import CookieConsent, { Cookies } from "react-cookie-consent";
import ScrollIntoView from 'react-scroll-into-view'
import videojs from "video.js";
import ReactHlsPlayer from 'react-hls-player';
import logo from "../Images/wp_images/logo_white_blue.png";
import datingLogo from "../Images/wp_images/datingLogo.png";

import arc from "../Images/wp_images/arc.png";
import DemoVideo from "./DemoVideo";
import {
  useDispatch, useSelector
} from 'react-redux';
import { useRef } from "react";
import { submitEmailNewsletter } from "../redux/actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import connection from "../config/connection";
import { toast } from "react-toastify";

function Footer(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");


  const [newsLetterDetails, setNewsLetterDetails] = useState({ Name: "", email: "" })
  const auth = useSelector(state => state);
  const [ShowDemo, setDemoVideo] = useState(false);
  const [baseName, setBaseName] = useState('home');
  const [basePath, setBasePath] = useState(connection.homePage);
  const [baseOauth, setBaseOauth] = useState('oauth');

  const history = useHistory()
  const handleDemoVideo = (param) => {
    setDemoVideo(param)
  }


  useEffect(() => {

    if (splitLocation[1] == 'dating') {
      setBasePath(connection.datingHomePage);
      setBaseName('dating');
      setBaseOauth('dating');
    }
    else {
      setBasePath(connection.homePage);
      setBaseName('home');
      setBaseOauth('oauth');
    }
  });


  const submitNewletterEmail = (e) => {
    e.preventDefault();

    dispatch(submitEmailNewsletter(
      newsLetterDetails,
      auth.auth.loginUserToken ? auth.auth.loginUserToken : "",
      (result) => {
        if (result.id) {
          toast("Your email has been submitted successfully.", {
            position: "top-right",
            autoClose: 3000,
            type: "success",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          setNewsLetterDetails({ Name: "", email: "" })
        } else {
          toast(result.email[0], {
            position: "top-right",
            autoClose: 3000,
            type: "error",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          return false;
        }
      }
    ))
  };

  const handlechange = (e) => {
    const name = e.target.name;
    const value = e.target.value

    setNewsLetterDetails({ ...newsLetterDetails, [name]: value })
  }

  return (
    <div>
      {/* <CookieConsent
      enableDeclineButton
      flipButtons
      location="bottom"
      declineButtonText="Allow only essential cookies"
      buttonText="Allow all cookies"
      style={{ background: "#921FC4", color:'#fff', zIndex: "99999" }}
      declineButtonStyle={{ color: "#921FC4", fontSize: "13px", background:'#fff', borderRadius:'20px' }}
      buttonStyle={{ color: "#921FC4", fontSize: "13px", background:'#fff', borderRadius:'20px' }}
      expires={150}
    >
      This site uses cookies to make it work properly. For more information, see our <Link to="/home/privacy-policy/" style={{color: '#fff'}}>Privacy Policy</Link>.
    </CookieConsent> */}
      <img className="arc_img" src={arc} alt="arc" />
      <section className="new_footer_custom">
        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
        <path class="elementor-shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
        c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
        c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
      </svg> */}
        <div className="container custom_h_container">
          <div className="row">
            <div className="col-md-4">
              <div className="new_footer_first">
                <img className="footerlogo_custom_img" src={splitLocation[1] == 'dating' ? datingLogo : logo} alt="logo" onClick={() => { window.location.href = auth.auth.loginUserToken ? connection.homePage + `?access_token=${auth.auth.loginUserToken}` : connection.homePage }} />
                <ul class="soical_outer newsocial_ul">
                  <li><a href="https://www.facebook.com/profile.php?id=100093151475876"><i class="fa fa-facebook-f"></i></a></li>
                  <li><a href="https://www.linkedin.com/company/eternaview/"><i class="fa fa-linkedin"></i></a></li>
                  <li><a href="https://www.youtube.com/@Eternaview/featured"><i class="fab fa-youtube"></i></a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-2">
              <ul className="footer_new_ul">
                {(() => {
                  if (baseName == 'dating') {
                    return (
                      <>
                        <li><Link onClick={() => { window.location.href = auth.auth.loginUserToken ? connection.datingHomePage + `?access_token=${auth.auth.loginUserToken}` : connection.datingHomePage }}>Home</Link></li>
                        <li><Link onClick={() => { window.location.href = auth.auth.loginUserToken ? `${connection.datingHomePage}create-dating` + `?access_token=${auth.auth.loginUserToken}` : `${connection.datingHomePage}create-dating` }}>Create</Link></li>
                        <li><Link to="/dating/interact/">Interact</Link></li>
                        <li><Link to="/dating/pricing-plans/">Pricing</Link></li>
                        <li><Link to="/dating/myeternaviews/">My Collections</Link></li>
                        {auth.auth.loginUserToken == "" &&
                          <li><Link to="/dating/sign-in/">Login/Register
                          </Link>
                          </li>}
                      </>
                    )
                  }
                  else {
                    return (
                      <>
                        <li><Link onClick={() => { window.location.href = auth.auth.loginUserToken ? connection.homePage + `?access_token=${auth.auth.loginUserToken}` : connection.homePage }}>Home</Link></li>
                        <li><Link onClick={() => { window.location.href = auth.auth.loginUserToken ? `${connection.homePage}create` + `?access_token=${auth.auth.loginUserToken}` : `${connection.homePage}create` }}>Create</Link></li>
                        <li><Link to="/home/interact/">Interact</Link></li>
                        <li><Link to="/home/pricing-plans/">Pricing</Link></li>
                        <li><Link to="/home/myeternaviews/">My Collections</Link></li>
                        {auth.auth.loginUserToken == "" &&
                          <li><Link to="/oauth/sign-in/">Login/Register
                          </Link>
                          </li>}
                      </>
                    )
                  }
                })()}
              </ul>
            </div>
            <div className="col-md-2">
              <ul className="footer_new_ul">
                {(() => {
                  if (baseName == 'dating') {
                    return (
                      <>
                        <li><Link onClick={() => { window.location.href = auth.auth.loginUserToken ? `${connection.datingHomePage}about-dating/#demo1?access_token=${auth.auth.loginUserToken}` : `${connection.homePage}about-dating/#demo1` }} >Demo</Link></li>
                        <li><Link onClick={() => { window.location.href = auth.auth.loginUserToken ? `${connection.datingHomePage}blog-dating?access_token=${auth.auth.loginUserToken}` : `${connection.homePage}blog-dating` }}>Blog
                        </Link>
                        </li>
                        <li><Link onClick={() => { window.location.href = auth.auth.loginUserToken ? `${connection.homePage}about-us-dating?access_token=${auth.auth.loginUserToken}` : `${connection.homePage}about-us-dating` }} >About us
                        </Link>
                        </li>
                        <li><Link onClick={() => { window.location.href = auth.auth.loginUserToken ? `${connection.homePage}faq-dating?access_token=${auth.auth.loginUserToken}` : `${connection.homePage}faq-dating` }}>FAQ
                        </Link>
                        </li>
                        <li><Link to="/dating/contact-us/">Support/Contact Us
                        </Link>
                        </li>
                        <li><a href="#">Phone: 201.618.8422</a></li>

                      </>
                    )
                  }
                  else {
                    return (
                      <>
                        <li><Link onClick={() => { window.location.href = auth.auth.loginUserToken ? `${connection.homePage}about/#demo1?access_token=${auth.auth.loginUserToken}` : `${connection.homePage}about/#demo1` }} >Demo</Link></li>
                        <li><Link onClick={() => { window.location.href = auth.auth.loginUserToken ? `${connection.homePage}blog?access_token=${auth.auth.loginUserToken}` : `${connection.homePage}blog` }}>Blog
                        </Link>
                        </li>
                        <li><Link onClick={() => { window.location.href = auth.auth.loginUserToken ? `${connection.homePage}about-us?access_token=${auth.auth.loginUserToken}` : `${connection.homePage}about-us` }} >About us
                        </Link>
                        </li>
                        <li><Link onClick={() => { window.location.href = auth.auth.loginUserToken ? `${connection.homePage}faq?access_token=${auth.auth.loginUserToken}` : `${connection.homePage}faq` }}>FAQ
                        </Link>
                        </li>
                        <li><Link to="/home/contact-us/">Support/Contact Us
                        </Link>
                        </li>
                        <li><a href="#">Phone: 201.618.8422</a></li>
                      </>
                    )
                  }
                })()}
              </ul>
            </div>
            {console.log(newsLetterDetails, "<- newsLetterDetails")}
            <div className="col-md-3">
              <div className="new_footer_first_last">
                <p className="keep_informed_title">Keep informed on everything Eternaview</p>
                <form onSubmit={submitNewletterEmail}>
                  <input className="footer_input" type="text" name="Name" onChange={handlechange} placeholder="Your First Name" />
                  <input className="footer_input" type="text" name="email" onChange={handlechange} placeholder="Your Email" />
                  <button className="submit_btn_footer" type="submit">Submit</button>
                </form>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr className="footer_hr_new" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p className="footer_p_new">© 2024 Eternaview. All rights reserved.</p>
            </div>
            <div className="col-md-4 footer_four_col">
              <p className="footer_p_new"><Link className="terms_p" to={`/${baseName}/terms-of-use/`}>Terms of Use</Link></p>
            </div>
            <div className="col-md-4">
              <p className="footer_p_new"><Link className="terms_p" to={`/${baseName}/privacy-policy/`}>Privacy  Policy
              </Link></p>
            </div>
          </div>
        </div>
        <DemoVideo isOpen={ShowDemo} handleDemoVideo={handleDemoVideo} />
      </section>
    </div>);
};

export default Footer;
